<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>FAQs</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search FAQs"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed color="accent" class="mr-2" @click="openFaq()">
              <v-icon left>mdi-plus</v-icon>Add FAQ
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  v-on="on"
                  :to="{ name: $route.name + '-categories' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Categories</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="faqs"
            :items-per-page="-1"
            no-data-text="No FAQs can be found"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="openFaq(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit/View FAQ</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    class="ml-2"
                    @click="openDelete(item)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete FAQ</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="faqDialog.open"
      max-width="600"
      @click:outside="resetFaq()"
    >
      <v-card>
        <v-form @submit.prevent="saveFaq" method="post">
          <v-card-title
            >{{ faqDialog.isEditing ? "Edit" : "Add" }} an FAQ</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Question Title"
              type="text"
              v-model="faqDialog.fields.title"
              required
              outlined
              dense
              :error="faqDialog.errors.hasOwnProperty('title')"
              :error-messages="faqDialog.errors['title']"
            ></v-text-field>
            <v-textarea
              label="Answer"
              rows="4"
              v-model="faqDialog.fields.content"
              required
              outlined
              dense
              :error="faqDialog.errors.hasOwnProperty('content')"
              :error-messages="faqDialog.errors['content']"
            ></v-textarea>
            <v-select
              label="Category"
              v-model="faqDialog.fields.category_id"
              :items="categories"
              item-text="title"
              item-value="id"
              required
              outlined
              dense
              :error="faqDialog.errors.hasOwnProperty('category_id')"
              :error-messages="faqDialog.errors['category_id']"
            ></v-select>
            <v-text-field
              label="Anchor"
              type="text"
              v-model="faqDialog.fields.anchor"
              required
              outlined
              dense
              :error="faqDialog.errors.hasOwnProperty('anchor')"
              :error-messages="faqDialog.errors['anchor']"
            ></v-text-field>
            <v-text-field
              label="Order"
              type="text"
              v-model="faqDialog.fields.display_order"
              required
              outlined
              dense
              :error="faqDialog.errors.hasOwnProperty('display_order')"
              :error-messages="faqDialog.errors['display_order']"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetFaq()">Close</v-btn>
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="faqDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete FAQ</v-card-title>
        <v-card-text
          >Are you sure you want to delete
          <strong>{{ deleteDialog.faq.title }}</strong
          >?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Question Title", value: "title" },
        { text: "Category", value: "category.title" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      faqDialog: {
        open: false,
        fields: {
          title: null,
          content: null,
          category_id: null,
          anchor: null,
          display_order: null,
        },
        loading: false,
        isEditing: false,
        faq: {},
        errors: {},
      },
      deleteDialog: {
        open: false,
        loading: false,
        faq: {},
      },
    };
  },

  computed: {
    faqs() {
      let faqs = this.$store.state.paw["faqs"];

      if (this.searchTerm != "") {
        faqs = faqs.filter((f) => {
          const searchTerm = this.searchTerm.toLowerCase();

          const title = f.title.toLowerCase();
          const categoryTitle = f.category.title.toLowerCase();

          return (
            title.includes(searchTerm) || categoryTitle.includes(searchTerm)
          );
        });
      }

      return faqs;
    },
    categories() {
      return this.$store.state.paw["faqCategories"];
    },
  },

  methods: {
    openFaq: function (faq = null) {
      if (faq !== null) {
        this.faqDialog.fields.title = faq.title;
        this.faqDialog.fields.content = faq.content;
        this.faqDialog.fields.category_id = faq.category_id;
        this.faqDialog.fields.anchor = faq.anchor;
        this.faqDialog.fields.display_order = faq.display_order;
        this.faqDialog.isEditing = true;
        this.faqDialog.faq = faq;
      }

      this.faqDialog.open = true;
    },

    resetFaq: function () {
      this.faqDialog.open = false;
      this.faqDialog.fields.title = null;
      this.faqDialog.fields.content = null;
      this.faqDialog.fields.category_id = null;
      this.faqDialog.fields.anchor = null;
      this.faqDialog.fields.display_order = null;
      this.faqDialog.loading = false;
      this.faqDialog.faq = {};
      this.faqDialog.errors = {};
      this.faqDialog.isEditing = false;
    },

    saveFaq() {
      const appId = this.$route.params.id;
      this.faqDialog.loading = true;
      this.faqDialog.errors = {};

      let payload = {
        appId,
        isEditing: this.faqDialog.isEditing,
        fields: this.faqDialog.fields,
      };

      if (this.faqDialog.isEditing) {
        payload.faqId = this.faqDialog.faq.id;
      }

      this.$store
        .dispatch("paw/saveFaq", payload)
        .then(() => {
          this.resetFaq();
        })
        .catch((err) => {
          this.faqDialog.errors = err.response.data.errors;
          this.faqDialog.loading = false;
        });
    },

    openDelete(faq) {
      this.deleteDialog.faq = faq;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.faq = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("paw/deleteFaq", {
          appId,
          faqId: this.deleteDialog.faq.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
